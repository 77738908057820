import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-list-file-template',
  templateUrl: './list-file-template.component.html',
  styleUrl: './list-file-template.component.scss'
})
export class ListFileTemplateComponent implements OnInit{
  @Input() files = [];
  @Input() type = "";
  @Output() update = new EventEmitter<any>();

  extensionsAttr = [
    { ext:['xls', 'xlsx', 'csv'], color:'#1BBB53', icon:'fa-file-excel' },
    { ext:['txt'], color:'#03A9F4', icon:'fa-file-alt' },
    { ext:['doc', 'docx', 'docm' ,'aspx'], color:'#3C33FF', icon:'fa-file-word' },
    { ext:['jpg', 'jpeg', 'gif', 'png', 'bmp', 'psd'], color:'#BF00FC', icon:'fa-file-image' },
    { ext:['avi', 'mp4', 'mpeg', 'mwv'], color:'#00E9FC', icon:'fa-file-video' },
    { ext:['ppt', 'pptx', 'pptm'], color:'#FF5722', icon:'fa-file-powerpoint' },
    { ext:['pdf'], color:'#E91E63', icon:'fa-file-pdf' },
    { ext:['html', 'css', 'js', 'json'], color:'#E2D63D', icon:'fa-file-code' },
    { ext:['mp3', 'wav', 'wma'], color:'#D1AE2E', icon:'fa-file-audio' },
    { ext:['zip', 'rar', 'gzip', 'xml'], color:'#A67711', icon:'fa-file-archive' },
    { ext:['etc'], color:'#9E9E9E', icon:'fa-file' }
  ];

  getRequests = [];
  loadDownloadFiles = false;
  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {
    console.log(this.files)
  }

  updateFiles(){
    this.update.emit(this.files);
  }

  deleteFile(file, i){
    this.files.splice(i, 1);
    this.globalService.sendRequest({severity: 'success',summary: 'Éxito',detail: 'DOCUMENT_DELETE_SUCCESS',type: 'TOAST'});
    this.updateFiles();
  }

  getColor(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['color'] : '#9e9e9e';
  }
  
  getIcon(name){
    let ext = name.split(".")[name.split(".").length - 1].toLowerCase();
    return this.extensionsAttr.find(c => c['ext'].includes(ext)) ? this.extensionsAttr.find(c => c['ext'].includes(ext))['icon'] : 'fa-file';
  }

  downloadFile(file){
    window.open(file.url, '_blank');
  }
}
